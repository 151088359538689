import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
  LoaderFunctionArgs,
  redirect,
  type LinksFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useLoaderData,
  json,
} from '@remix-run/react'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import appStyleSheetUrl from './styles/app.css?url'
import { Toaster } from '@/components/ui/sonner'
import { GeneralErrorBoundary } from '@/components/error-boundary'
import { useToast } from '@/components/toaster'
import { useNonce } from '@/lib/nonce-provider'
import { posthog } from 'posthog-js'
import { useEffect } from 'react'

import AuthLayout from './layouts/auth-layout'
import AppLayout from './layouts/app-layout'
import MarketingLayout from './layouts/marketing-layout'
import Q2Layout from './layouts/q2-layout'
import { getToast } from '@/lib/toast.server'
import { combineHeaders, primaryProperty } from '@/lib/utils'
import { JoystUser, requireUser } from '@/lib/session.server'

export type RootLoaderData = {
  apiUser: JoystUser
}

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
    { rel: 'stylesheet', href: appStyleSheetUrl },
  ].filter(Boolean)
}

const publicRoutes = [
  '/auth',
  '/blog',
  '/faq',
  '/about',
  '/banking',
  '/real-estate',
  '/brokerages',
]

const validStates = ['CA', 'UT', 'OR']

export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers: toastHeaders } = await getToast(request);
  const url = new URL(request.url),
        pathname = url.pathname,
        params = url.searchParams,
        skipMoreInfo = !!params.get("skip");

  let apiUser;
  if (pathname !== '/' && !publicRoutes.find(p => pathname.startsWith(p))) {
    apiUser = await requireUser(request);

    // Dashboard depends on the user having at least one assigned property
    const property = primaryProperty(apiUser);
    const addressSearchRoute = '/address/search';
    const notSupportedRoute = '/address/not-supported';
    const addressMoreInfoRoute = '/address/more-info';

    if (!apiUser.properties || apiUser.properties.length < 1) {
      if (pathname !== addressSearchRoute) {
        return redirect(addressSearchRoute);
      }
    } else if (!apiUser?.properties.find(p => validStates.includes(p.state))) {
      if (pathname !== notSupportedRoute) {
        return redirect(notSupportedRoute);
      }
    } else if (
      pathname !== addressMoreInfoRoute &&
      property &&
      (
        !property.bathrooms_total &&
        !property.bedrooms_total &&
        !property.lot_size_square_feet &&
        !property.living_area_square_feet
       ) &&
       !property.missing_info_alert_ack
    ) {
      return redirect('/address/more-info')
    }
  }

  return json(
    {
      toast,
      apiUser,
    },
    {
      headers: combineHeaders(toastHeaders, request.headers),
    },
  )
}

export function Document({
  children,
  nonce,
}: {
  children: React.ReactNode
  nonce: string
}) {
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

const marketingUrls = [
  '',
  'faq',
  'about',
  'blog',
  'homeowners',
  'brokerages',
  'banking',
  'real-estate',
]

function App() {
  const data = useLoaderData<typeof loader>()
  const nonce = useNonce()
  // TODO: Test that this is only rendering once once in production
  // and development URLs have been excluded from recording events in PH settings.
  // See: https://posthog.com/tutorials/remix-analytics#capturing-pageviews
  const location = useLocation()
  const basePath =
    location && location.pathname.length > 1
      ? location.pathname.split('/').filter(Boolean)[0].split('?')[0]
      : ''
  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])
  useToast(data.toast)

  return (
    <Document nonce={nonce}>
      {basePath === 'auth' || basePath === 'address' ? (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ) : marketingUrls.includes(basePath) ? (
        <MarketingLayout>
          <Outlet />
        </MarketingLayout>
      ) : basePath.startsWith('q2') ? (
        <Q2Layout>
          <Outlet />
        </Q2Layout>
      ) : (
        <AppLayout basePath={basePath}>
          <Outlet />
        </AppLayout>
      )}
      <Toaster position="bottom-right" />
    </Document>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  const nonce = useNonce()
  captureRemixErrorBoundaryError(error)

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  )
}
