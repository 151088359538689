import { Link, useRouteLoaderData } from '@remix-run/react'
import { cn } from '@/lib/utils'
import { Icon } from '@/components/ui/icon'
import { RootLoaderData } from '@/root'

const navItems = [
  { href: '/dashboard', icon: 'layout', label: 'Capital' },
  { href: '/vault', icon: 'layers', label: 'Vault' },
] as const

export default function AppLayout({
  basePath,
  children,
}: {
  basePath: string
  children: React.ReactNode
}) {
  const { apiUser } = useRouteLoaderData<RootLoaderData>('root') || {}
  return (
    <div
      className={cn([
        'min-w-dvw grid min-h-dvh grid-rows-[min-content_1fr] bg-gray-900 pt-4',
        'transition-all md:grid-cols-[200px_1fr] md:grid-rows-none md:py-4 md:pl-4 lg:grid-cols-[250px_1fr]',
      ])}
    >
      <nav className="nav flex flex-col justify-between gap-4 px-4 text-white md:pl-0 md:pr-4">
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 items-center gap-4 md:grid-cols-1">
            <div className="flex justify-start py-2">
              <Icon name="joyst" className="h-[32px] w-[95px]" />
            </div>
          </div>

          <ul className="grid list-none grid-cols-2 gap-2 md:grid-cols-1 md:flex-col">
            {navItems.map(navItem => (
              <li
                key={navItem.href}
                className="navitem cursor-pointer rounded-md font-semibold"
              >
                <Link
                  className={cn(
                    navItem.href.includes(basePath)
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                    'group flex w-full gap-x-3 rounded-md px-2 py-3 font-semibold leading-6 md:py-5',
                  )}
                  to={navItem.href}
                >
                  <Icon
                    name={navItem.icon}
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  {navItem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-4">
          <ul className="hidden list-none grid-cols-2 justify-end gap-2 md:grid md:grid-cols-1">
            <Link
              to="mailto:support@joystcorp.com"
              className="flex cursor-pointer gap-2 rounded-md px-2 py-3 font-semibold"
            >
              <Icon
                name="bar-chart-2"
                className="h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              Support
            </Link>
            <Link
              to="/settings"
              className="flex cursor-pointer gap-2 rounded-md px-2 py-3 font-semibold"
            >
              <Icon
                name="settings"
                className="h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              Settings
            </Link>
          </ul>
          {!!apiUser && !!apiUser.brokerage && !!apiUser.agent && (
          <div className="profile hidden grid-cols-3 gap-3 rounded-md bg-gray-700 p-4 md:grid md:grid-cols-1">
            <div className="flex justify-start">
              <div className="overflow-clip rounded-lg bg-white px-3">
                <img src="/harcourts.png" alt={ apiUser.brokerage.name } />
              </div>
            </div>

            <div className="flex items-center">
                <img
                    width="40px"
                    height="40px"
                    src={ apiUser.agent.profile_picture_url ? apiUser.agent.profile_picture_url : '/avatar.png' }
                    className="rounded-full mr-2"
                    alt={ apiUser.agent.first_name + ' ' + apiUser.agent.last_name }
                />
                <div className="flex-1">
                    <div className="hidden text-left md:block">
                        <div className="whitespace-nowrap font-semibold">{ apiUser.agent.first_name } { apiUser.agent.last_name } • Agent</div>
                        <div className="whitespace-nowrap">{ apiUser.brokerage.name }</div>
                    </div>
                    <div className="whitespace-nowrap">
                        { apiUser.agent.email }
                    </div>
                </div>
            </div>
          </div>
          ) }
        </div>
      </nav>

      <main className="relative overflow-clip bg-white md:rounded-bl-3xl md:rounded-tl-3xl">
        <div className="page-grid absolute left-0 top-0 h-full w-full overflow-y-scroll">
          {children}
        </div>
      </main>
    </div>
  )
}
